import gql from 'graphql-tag'
import userFragment from './UserFragment.directive'

export const USER_QUERY = gql`
  query User {
    user {
      ...${userFragment}
    }
  }
`
export const SEND_STATUS = gql `
  mutation sendStatus($user_id:ID,$description:String){
    sendStatus(user_id:$user_id,description:$description){
        description
    }
  }
`