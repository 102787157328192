import gql from 'graphql-tag'

export default gql`{
  id
  email
  entity {
    id
    address
    areasExpertise
    email
    license
    name
    nuit
    telephone
  }
  name
  office
  photo
  type
  telephone
  role {
    id
    name
  }
}
`